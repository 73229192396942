import React from 'react';
import "./HowToPlay.scss";
import how1 from "../../Images/how1.png";
import how2 from "../../Images/how2.png";
import how3 from "../../Images/how3.png";
import how4 from "../../Images/how4.png";
import how5 from "../../Images/how5.png";
const HowToPlay = () => {
  return (
    <div className="container">

    <div className="HowToPlayContainer">
    <div className='howtoplayHeader'>How to Play ?</div>
    <div className="HowToPlaytextContainerThird">
    <img src={how1} className="HowToPlayImage" alt="Logo" />
    <img src={how2} className="HowToPlayImage" alt="Logo" />
    <img src={how3} className="HowToPlayImage" alt="Logo" />
    <img src={how4} className="HowToPlayImage" alt="Logo" />
    <img src={how5} className="HowToPlayImage" alt="Logo" />
      {/* <div className="HowToPlaythirdcomptext">Questing</div> */}
      {/* <div className="HowToPlaythirdcomptextNote">You can send</div> */}
    </div>
  </div>
    </div>
  )
}

export default HowToPlay