import React, { useState } from "react";
import androidIc from "../../Images/android-white.png";
import appstoreWhite from "../../Images/apple.png";
import QrCode from "../../Images/qr-code2.png";
import "./DownloadAndroid.scss";
import FileDownloadButton from "./FileDownloadButtonAndroid";

const DownloadAndroid = () => {
  const [show, setShow] = useState(false);
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const handleModalOpen = async () => {
    setShow(true);
  };
  const handleModalClose = () => {
    setShow(false);
  };
  return (
    <div className="androidWrapper">
      {/* android box componenet */}

      <div className="androidBox">
        <div className="androidIconContainer">
          <img src={androidIc} className="androidIcon" alt="Logo" />
          <FileDownloadButton
            fileUrl="https://firebasestorage.googleapis.com/v0/b/chatandmoney-3033.appspot.com/o/uploads%2Fchatandmoney.apk?alt=media&token=0df38f94-a1ca-412e-b8e5-288f401c4dcf"
             fileName="Chatandmoney.apk"
            name="Android app"
          >


          </FileDownloadButton>
        </div>
      </div>
      {/* ios box componenet */}
      <div className="androidBox" onClick={() => handleModalOpen()}>
        <div className="androidIconContainer">
          <img src={appstoreWhite} className="androidIcon" alt="Logo" />
          <div className="buttonAndroid">
      <button className="androidTextlogo">
        iOS app
      </button>
    </div>
        </div>
          <div className="buttonComingsoon">
            <button className="androidTextlogoComingsoon">Coming Soon*</button>
          </div>
      </div>
      {/* Web box componenet */}

      <img src={QrCode} className="QrCode" alt="Logo" />
    </div>
  );
};

export default DownloadAndroid;
