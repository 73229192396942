import React, { useState, useEffect } from "react";
import "./Header.scss";
import "./Modal.scss";
import fghi from "../../Images/launcher.png";
import image2 from "../../Images/Tutorials/image2.png";
import hamburger from "../../Images/hamburger.png";
import close from "../../Images/close.png";

const Header = () => { 
  const [isHeaderShadowed, setIsHeaderShadowed] = useState(false);
  const [show, setShow] = useState(false);
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const handleModalOpen = async () => {
    setShow(true);
  };
  const handleModalClose = () => {
    setShow(false); 
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window?.scrollY;
      if (scrollPosition > 0) {
        setIsHeaderShadowed(true);
      } else { 
        setIsHeaderShadowed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setStatusBarColor = (color) => {
    let metaTag = document.querySelector('meta[name="theme-color"]');

    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.name = "theme-color";
      document.head.appendChild(metaTag);
    }

    metaTag.content = color;
  };

  const color = "#6b4fff";

  useEffect(() => {
    setStatusBarColor(color);
  }, [color]);

  return (
    <div className={`header ${isHeaderShadowed ? "shadow" : ""}`}>
      <div className="appIcon">
        <img
          src={fghi}
          alt="Logo"
          width={"60px"}
          height={"60px"}
          borderRadius={30}
        />
        <span className="appName">Chat&money</span>
      </div>

      <div className={showHideClassName}>
        <section className="modal-main">
          {show ? (
            <div className="itemsLink">
              <span className="optionsItem">Questing</span>
              <span className="optionsItem">Features</span>
              <span className="optionsItem">How to Play</span>
              <span className="optionsItem">How to Earn Money ?</span>
              <span className="optionsItem">Latest App</span>
            </div>
          ) : null}
        </section>
      </div>

      {show ? (
        <div className="hamIcon" onClick={() => handleModalClose()}>
          <img
            className="Show"
            width={"30px"}
            height={"30px"}
            src={close}
            alt="Close Button"
          />
        </div>
      ) : (
        <div className="hamIcon" onClick={() => handleModalOpen()}>
          <img
            className="Show"
            width={"30px"}
            height={"30px"}
            
            src={hamburger}
            alt="HamIcons"
          />
        </div>
      )}
    </div>
  );
};

export default Header;
